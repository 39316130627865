// @use '~bootstrap/scss/functions';
// @use '~bootstrap/scss/mixins';
// @use '~bootstrap/scss/variables';
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

// fonts
$enable-responsive-font-sizes: true;
$font-family-arabic: 'Tajawal', sans-serif;
$line-height-base: 1.57;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.375; //22px
$h2-font-size: $font-size-base * 1.25; // 20px
$h3-font-size: $font-size-base; // 16px
$h4-font-size: $font-size-base * 0.875; // 14px
$h5-font-size: $font-size-base * 0.75; // 12px

$font-weight-bold: 500;
$headings-line-height: 1.57;

// colors & theme
$blue: #3e48a6;
$red: #c83c3e;
$green: #9BEC09;
$pink: #ec9ce6;
$gray: #edeef3;
$yellow: #ffc042;
$orange: #f59623;
$light-pink: #fff4fe;
$light-green: #e2f7f6;
$light-blue: #e8eaff;
$separator-gray: #f2f3f8;
$separator-gray-light: #eeeff4;
$secondary-gray: #e9edf4;

$dark-blue: #111135;
$secondary-light-blue: #e8eaff;
$dusky-blue: #5059ae;
$gray-500: #f2f4f7;
$gray-600: #b6bcd1;
$gray-800: #585871;
$gray-bg: #fcfcfc;

// body
$body-color: $dark-blue;
$body-bg: #fff;

// list groups
$list-group-bg: #fff;
$list-group-border-color: $separator-gray;
$list-group-item-padding-x: 1.5rem;
$list-group-item-padding-y: 1.25rem;

// buttons & inputs
$btn-border-radius-lg: 8px;
$font-size-lg: $font-size-base * 0.875;
$input-btn-padding-y-lg: 0.65rem;
$btn-font-weight: $font-weight-bold;
$input-btn-font-size: 0.875rem;

$btn-border-radius-sm: 6px;
$font-size-sm: $font-size-base * 0.75;
$input-btn-padding-y-sm: 6px;
$input-btn-padding-x-sm: 12px;

// Forms
$input-color: $dark-blue !important;
$input-border-color: $separator-gray-light;
$input-box-shadow: none;
$input-btn-focus-width: 0;

// cards
$card-cap-bg: $secondary-light-blue;
$card-border-radius: 12px;
$card-spacer-y: 8px;
$card-spacer-x: 16px;
$card-border-width: 0;

// grid
$grid-gutter-width: 48px;

// Alerts
$alert-padding-y: 1rem;
$alert-padding-x: 1rem;
// $alert-bg-level: 0;
// $alert-border-level:                -9 !default;
// $alert-color-level:                 6 !default;

// custom control
$custom-control-cursor: pointer;
$custom-control-indicator-size: 1.25rem !default;
$custom-control-indicator-border-color: $gray-600;
$custom-control-indicator-border-width: 0.9px;
$custom-control-indicator-checked-bg: $green;
$custom-control-indicator-checked-disabled-bg: rgba($green, 0.5) !default;
$custom-control-indicator-checked-border-color: $green;
$custom-control-indicator-focus-box-shadow: 0 0 0 0.2rem rgba($green, 0.25);
$custom-control-indicator-focus-border-color: lighten($green, 25%);
$custom-control-indicator-active-bg: lighten($green, 35%) !default;
$custom-checkbox-indicator-border-radius: 2.3px;

// theme-colors
$theme-colors: (
  'primary': $blue,
  'secondary': $green,
  'success': $green,
  'danger': $red,
  'warning': $yellow,
  // info
  'light': $gray-bg,
  'dark': $dark-blue,
  // Custom
  'blue': $blue,
  'green': $green,
  'red': $red,
  'pink': $pink,
  'yellow': $yellow,
  'orange': $orange,
  'separator-gray': $separator-gray,
  'gray-dark': $gray-800,
  'gray-bg': $gray-bg,
  'cloudy-blue': $gray-600,
  'white': white,
);

// spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
    // 4px
  ),
  2: (
    $spacer * 0.5,
    // 8px
  ),
  3: (
    $spacer * 0.75,
    // 12px
  ),
  4: (
    $spacer * 0.875 // 14px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
  ),
  5: $spacer,
  // 16px
  6:
    (
      $spacer * 1.25,
      // 20px
    ),
  7: (
    $spacer * 1.5,
    //24px
  ),
  8: (
    $spacer * 1.625,
    // 26px
  ),
  9: (
    $spacer * 2.125,
    // 34px
  ),
  10: (
    $spacer * 2.1875,
    // 35px
  ),
  18: (
    $spacer * 1.125 //18px,,,,,,,,,,,,,,,,,,,,,,,,,,,,
  ),
);
